// @flow
import React from 'react';
import ProjectLayout from '../components/ProjectLayout/ProjectLayout';
import CharliePizzaContent from '../components/ProjectsContent/CharliePizzaContent/CharliePizzaContent';

const CharliePizzaPage = ({location: {pathname}}) => {
  const description = () => (
    <div>
      A collection of posters for Charlie Pizza restaurant.
      <br/><br/>
      Made in Virtuvės mitų griovėjai.
    </div>
  );

  return (
    <ProjectLayout pathname={pathname} description={description()}>
      <CharliePizzaContent/>
    </ProjectLayout>
  );
};

export default CharliePizzaPage;
